import * as Yup from "yup";

export const isValidEmail = () =>
  Yup.string()
    .email(`wrong email`)
    .matches(/^[A-z+0-9+\+\-_^.+@+.]+$/, "wrong email");

export const isValidName = () =>
  Yup.string()
    .min(2, "name must be longer")
    .max(30, "name must be shorter")
    .matches(/^[A-z+0-9+]+$/, "not valid name");

export const isValidSurname = () =>
  Yup.string()
    .min(2, "surname must be longer")
    .max(30, "surname must be shorter")
    .matches(/^[A-z+0-9+]+$/, "not valid surname");

export const isValidLetter = () =>
  Yup.string()
    .min(30, "letter must be longer")
    .max(300, "letter must be shorter");

export const isValidPhone = () =>
  Yup.string()
    .typeError("must be a number")
    .min(8, "minimum number length is 8 digits")
    .max(15, "maximum number length is 15 digits");

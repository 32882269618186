import { useFormik } from "formik";
import Input from "../../../../components/input/Input";
import { useFormikHelper } from "../../../../system/helpers/formikHelperHook";
import Btn from "../../../../components/btn";
import TextArea from "../../../../components/textArea/TextArea";
import { ContactUsSchema } from "./constants";

const ContactUsForm = ({ onSubmit, isSpinner }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      email: "",
      letter: "",
    },
    validateOnBlur: true,
    validationSchema: ContactUsSchema(),
    onSubmit: onSubmit,
  });

  const { error, onChange, onBlur } = useFormikHelper(formik);

  return (
    <div className={"form"}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className={"form__title"}>Personal information</div>
          <div className={"form__info"}>
            <Input
              placeholder={"First name"}
              targetVal
              value={formik.values.name}
              onChange={(val) => onChange("name", val)}
              onBlur={() => onBlur("name")}
              error={error("name")}
            />
            <Input
              placeholder={"Last name"}
              targetVal
              value={formik.values.surname}
              onChange={(val) => onChange("surname", val)}
              onBlur={() => onBlur("surname")}
              error={error("surname")}
            />
            <Input
              placeholder={"Email Address"}
              targetVal
              value={formik.values.email}
              onChange={(val) => onChange("email", val)}
              onBlur={() => onBlur("email")}
              error={error("email")}
            />
          </div>
        </div>
        <div className={"form__letter"}>
          <div className={"form__title form__title-letter"}>Cover letter</div>
          <TextArea
            placeholder={"Is there something we should know about? (optional)"}
            targetVal
            value={formik.values.letter}
            name="letter"
            error={error("letter")}
            onChange={(val) => onChange("letter", val)}
          />
        </div>
        <div className={"form__btn"}>
          <Btn type={"secondary"} label={"Apply"} isLoading={isSpinner} />
        </div>
      </form>
    </div>
  );
};

export default ContactUsForm;
